import { Permission } from './../directives/permission.directive';
import { Injectable, inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
  CanActivateFn,
  ActivatedRoute,
  mapToCanActivate,
} from '@angular/router';
import { catchError, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';
@Injectable({
  providedIn: 'root',
})
export class PermissionGuard {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    const pageName = next.data['pageName'];
    if (pageName === 'contacts') return true;

    if (this.authService.hasPagePermission(pageName)) {
      return true;
    } else {
      this.router.navigate(['/not-authorized']);
      return false;
    }
  }
}

export const AuthGuard: CanActivateFn = (
  next: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
): boolean => {
  return inject(PermissionGuard).canActivate(next, state);
};
