import { Component } from '@angular/core';
import { MessageService } from 'primeng/api';
import { Contact } from 'src/app/models/Contact';
import { Result } from 'src/app/models/Result';
import { StatusTypeId } from 'src/app/models/Status';
import { User } from 'src/app/models/Users';

import { ApiServiceService } from 'src/app/services/api-service.service';
import { AuthServiceService } from 'src/app/services/auth-service.service';
import { GlobalVariables } from 'src/environments/environment';

@Component({
  selector: 'app-contacts-list',
  templateUrl: './contacts-list.component.html',
  styleUrls: ['./contacts-list.component.css'],
})
export class ContactsListComponent {
  password: string = '';
  errorMessage: string = '';
  isAuthorized: boolean = false;
  isConvertable: boolean = false;
  private correctPassword = '5S4Q3Ef{y#{m';
  public selectedUsers: User[] = [];
  public usersList: User[] = [];
  filterVisible = false;
  isLoading = false;
  totalCount: number = 0;
  totalPages: number = 0;
  currentPage = 1;
  pageSize = 100;
  displayedPages: number[] = [];

  contacts: Contact[] = [];
  filters: Contact;

  constructor(private apiService: ApiServiceService, private messageService: MessageService, private authService: AuthServiceService) {
    this.filters = new Contact();
  }

  ngOnInit(): void {
    if (sessionStorage['authorized'] === '1') {
      this.isAuthorized = true;
      this.fetchContacts();
     this.getAllUsers();
    }
  }

  submitPassword() {
    if (this.password === this.correctPassword) {
      sessionStorage.setItem('authorized', '1');
      this.isAuthorized = true;
      this.fetchContacts();
    } else {
      this.errorMessage = 'Incorrect password. Please try again.';
    }
  }

  checkBoxClicked(index: number) {
    //this.contacts[index].convertToLead ? this.contacts[index].convertToLead = false : this.contacts[index].convertToLead = true;
    this.contacts.some(x => x.convertToLead) ? this.isConvertable = true : this.isConvertable = false;
  }
  selectAllCheckBoxes() {
    this.contacts.forEach(contact => contact.convertToLead = true);
    this.isConvertable = true;
  }

  unselectAllCheckBoxes() {
    this.contacts.forEach(contact => contact.convertToLead = false);
    this.isConvertable = false;
  }

  headerCheckBoxClicked() {
    this.isConvertable ? this.unselectAllCheckBoxes() : this.selectAllCheckBoxes();
  }
  public getAllUsers() {
    this.apiService.getAllUsers(GlobalVariables.selectedProjectId).subscribe({
      next: (resp: Result) => {
        if (resp.status === 'success') {
          this.usersList = resp.data;
        } else {
          this.usersList = resp.data;
        }
      },
      error: (error) => {
        // Handle error
        console.error('error:', error);
      },
    });
  }
  openLeadModal() { }
  convertToLead() {
    var contactArray = this.contacts.filter(x => x.convertToLead);
    // Update isConvertedToLead to true for each object in the filtered array
    contactArray = contactArray.map(contact => ({
      ...contact,
      isConvertedToLead: true
    }));
    var leadContacts = contactArray.filter(x => x.isConvertedToLead).map(contact => ({
      ContactId: contact.id,
      Name: contact.name,
      //Email:contact.email,
      ConcernPerson: contact.name,
      ContactPerson: contact.name,
      StatusId: 3,
      PhoneNumber: contact.phoneNumber,
      MobileNumber: contact.cellPhone,
      City: contact.city,
      State: contact.state,
      IsConvertedToLead: contact.isConvertedToLead,
      UserIds: this.selectedUsers?.map((x) => x.id),
      CreatedByUserId: this.authService.currentUserValue.id
    }));;
    // this.apiService.convertToLead(leadContacts).subscribe({
    //   next: (resp: Result) => {
    //     this.isLoading = false;
    //     this.contacts = [];
    //     if (resp.status === 'success') {
    //       this.messageService.add({
    //         severity: 'success',
    //         summary: 'Success',
    //         detail: resp.message,
    //         life: 3000,
    //       });
    //     }
    //   },
    //   error: (error) => {
    //     // Handle error
    //     console.error('error:', error);
    //   },
    // });
  }
  fetchContacts() {
    if (!this.isAuthorized) {
      return;
    }

    this.isLoading = true;
    this.filters.pageNumber = this.currentPage;
    this.filters.pageSize = this.pageSize;

    this.apiService.getContacts(this.filters).subscribe({
      next: (resp: Result) => {
        this.isLoading = false;
        this.contacts = [];
        if (resp.status === 'success') {
          this.contacts = resp.data;

          this.apiService.getContactsCount(this.filters).subscribe({
            next: (resp: Result) => {
              if (resp.status === 'success') {
                this.totalCount = Number(resp.data);

                if (this.totalCount > 0) {
                  this.generateDisplayedPages();
                }
              }
            },
            error: (error) => {
              // Handle error
              console.error('error:', error);
            },
          });
        }
      },
      error: (error) => {
        // Handle error
        console.error('error:', error);
      },
    });
  }

  applyFilters(): void {
    this.fetchContacts();
  }

  resetFilters() {
    this.filters.name = null;
    this.filters.addressLine1 = null;
    this.filters.cityId = null;
    this.filters.state = null;
    this.filters.zip = null;
    this.filters.countyId = null;
    this.filters.exactAgeId = null;
    this.filters.estAgeId = null;
    this.filters.estIncomeId = null;
    this.filters.lengthOfResidenceId = null;
    this.filters.addrTypeId = null;
    this.filters.homeownerTypeId = null;
    this.filters.propertyTypeId = null;
    this.filters.medHMEVLId = null;
    this.filters.ethnicCDId = null;
    this.filters.genderId = null;
    this.filters.estWealthId = null;
    this.filters.phoneNumber = null;
  }

  onPageChange(pageNumber: number): void {
    this.currentPage = pageNumber;
    this.fetchContacts();
  }
  calculateTotalPages(totalItems: number, pageSize: number): number {
    return Math.ceil(totalItems / pageSize);
  }
  generateDisplayedPages(): void {
    this.totalPages = Math.ceil(this.totalCount / this.pageSize);
    const numberOfDisplayedPages = 3; // Number of page number buttons to display
    const halfTotalPagesToShow = Math.floor(numberOfDisplayedPages / 2);
    // Logic to generate an array of displayed page numbers
    const startPage = Math.max(1, this.currentPage - halfTotalPagesToShow);
    const endPage = Math.min(
      this.totalPages,
      this.currentPage + halfTotalPagesToShow
    );

    this.displayedPages = Array.from(
      { length: endPage + 1 - startPage },
      (_, index) => startPage + index
    );
  }
  onPageSizeChange(): void {
    this.currentPage = 1;
    this.fetchContacts();
  }
}
