import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ManageLeadsComponent } from './components/leads/manageleads/manage-leads.component';
import { MasterComponent } from './components/layout/master/master.component';
import { LoginComponent } from './components/login/login.component';
import { AuthGuard } from './gaurds/auth-guard';
import { ManageDealsComponent } from './components/deals/managedeals/manage-deals.component';
import { ManageEventsComponent } from './components/events/manageevents/manageevents.component';
import { OverallAnalyticsComponent } from './components/analytics/overall-analytics/overall-analytics.component';
import { ManageUsersComponent } from './components/users/manage-users/manage-users.component';
import { InvitationComponent } from './components/invitation/invitation.component';
import { ProjectsComponent } from './components/projects/manage-projects/projects.component';
import { PermissionGuard } from './gaurds/permission-gaurd';
import { ManageRolesComponent } from './components/roles-and-permissions/manage-roles/manage-roles.component';
import { UserSetupComponent } from './components/users/user-setup/user-setup.component';
import { ManageTeamsComponent } from './components/users/manage-teams/manage-teams.component';
import { NotAuthorizedComponent } from './components/layout/Error/not-authorized/not-authorized.component';
import { ContactsListComponent } from './components/contacts/contacts-list/contacts-list.component';

// Routes that require PermissionGuard
const routesWithPermissionGuard = [
  { path: 'events', component: ManageEventsComponent, pageName: 'events' },
  { path: 'users', component: ManageUsersComponent, pageName: 'users' },
  { path: 'teams', component: ManageTeamsComponent, pageName: 'teams' },
  { path: 'projects', component: ProjectsComponent, pageName: 'projects' },
  { path: 'leads', component: ManageLeadsComponent, pageName: 'leads' },
  { path: 'deals', component: ManageDealsComponent, pageName: 'deals' },
  { path: 'roles', component: ManageRolesComponent, pageName: 'roles' },
  { path: 'addUser', component: UserSetupComponent, pageName: 'addUser' },
  { path: 'contacts', component: ContactsListComponent, pageName: 'contacts' },
];

// Generate guarded routes with canActivate and data attributes
const guardedRoutes = routesWithPermissionGuard.map((route) => ({
  path: route.path,
  component: route.component,
  canActivate: [PermissionGuard],
  data: { pageName: route.pageName },
}));

const routes: Routes = [
  {
    path: '',
    component: MasterComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: OverallAnalyticsComponent,
      },
      ...guardedRoutes,
    ],
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'invitation',
    component: InvitationComponent,
  },
  {
    path: '**',
    component: NotAuthorizedComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
