import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationService, MessageService } from 'primeng/api';
import {
  DialogService,
  DynamicDialogConfig,
  DynamicDialogRef,
} from 'primeng/dynamicdialog';
import {
  Observable,
  debounceTime,
  distinctUntilChanged,
  switchMap,
  map,
  catchError,
  of,
} from 'rxjs';
import { Leads, LeadsFilter } from 'src/app/models/Leads';
import { Result } from 'src/app/models/Result';
import { LeadStatus } from 'src/app/models/Status';
import { User } from 'src/app/models/Users';
import { ApiServiceService } from 'src/app/services/api-service.service';
import { AuthServiceService } from 'src/app/services/auth-service.service';

@Component({
  selector: 'app-addupdatelead',
  templateUrl: './addupdatelead.component.html',
  styleUrls: ['./addupdatelead.component.css'],
})
export class AddupdateleadComponent {
  leads: Leads = new Leads();
  public usersList: User[] = [];
  public selectedUers: User[] = [];
  public selectedUersFilter: User[] = [];
  public leadStatusList: LeadStatus[] = [];
  public projectId = 0;
  public fromDate: any = new Date(
    new Date().setMonth(new Date().getMonth() - 1)
  ); //new Date;"1-1-2022";
  public toDate: any = new Date(); //"12-31-2022";
  isDataSaving: boolean;
  leadFormatter_search = (name: any) => name;

  constructor(
    private apiService: ApiServiceService,
    private authService: AuthServiceService,
    private activatedRoute: ActivatedRoute,
    private calendar: NgbCalendar,
    private dialogService: DialogService,
    private dialogRef: DynamicDialogRef,
    public dialogConfig: DynamicDialogConfig,
    private confirmationService: ConfirmationService,
    private messageService: MessageService
  ) {
    this.usersList = this.dialogConfig.data.users;
    this.projectId = this.dialogConfig.data.projectId;
    this.leads = this.dialogConfig.data.lead;
    this.selectedUers = this.dialogConfig.data.selectedUers;
    this.leadStatusList = this.dialogConfig.data.leadStatusList;
    this.toDate = this.calendar.getToday();
    this.fromDate = this.calendar.getPrev(this.calendar.getToday(), 'm', 1);
  }

  public validateAndSetData() {
    if (!this.selectedUers || this.selectedUers.length === 0) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Warning',
        detail: 'Please select at least one user',
        life: 3000,
      });
      return false;
    }

    if (!this.leads.statusId || this.leads.statusId === 0) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Warning',
        detail: 'Please select status',
        life: 3000,
      });
      return false;
    }

    if (!this.leads.name) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Warning',
        detail: 'Please fill the lead name',
        life: 3000,
      });
      return false;
    }

    // if (!this.leads.mobileNumber || !/^[0-9]+$/.test(this.leads.mobileNumber) || this.leads.mobileNumber.length > 14)
    // {
    //   this.messageService.add({
    //     severity: 'warn',
    //     summary: 'Warning',
    //     detail: 'Mobile number must be less than 14 digits and non-alphabetic',
    //     life: 3000,
    //   });
    //   return false;
    // }
    // if (!this.leads.phoneNumber || !/^[0-9]+$/.test(this.leads.phoneNumber) || this.leads.phoneNumber.length > 14)
    // {
    //   this.messageService.add({
    //     severity: 'warn',
    //     summary: 'Warning',
    //     detail: 'Phone number must be less than 14 digits and non-alphabetic',
    //     life: 3000,
    //   });
    //   return false;
    // }

    this.leads.userIds = this.selectedUers.map((x) => x.id);
    this.leads.createdByUserId = this.authService.currentUserValue.id;
    this.leads.updatedByUserId = this.authService.currentUserValue.id;
    this.leads.dateCreated = new Date();
    this.leads.dueDate = new Date();
    // this.leads.mobileNumber = this.leads.mobileNumber.toString();
    // this.leads.phoneNumber = this.leads.phoneNumber.toString();
    return true;
  }

  searchLead = (text$: Observable<string>) =>
    text$.pipe(
      // Wait for 200ms after each keystroke
      debounceTime(200),
      // Ignore if the term is the same as before
      distinctUntilChanged(),
      // Switch to a new Observable of API results
      switchMap((term) => this.getLeads(term))
    );

  public getLeads(term): Observable<any[]> {
    const filters = new LeadsFilter();
    filters.query = term;
    filters.projectId = this.projectId;
    if (!term) {
      return new Observable<any[]>();
    }

    return this.apiService.getMatchingLeadNames(filters).pipe(
      map((resp) => (resp.status === 'success' ? resp.data : [])),
      catchError((error) => {
        return of([]);
      })
    );
  }

  public onSubmit() {
    // Your form submission logic here
    this.leads.projectId = this.projectId;
    if (this.validateAndSetData()) {
      this.isDataSaving = true;
      this.apiService.addUpdateLeads(this.leads).subscribe({
        next: (resp: Result) => {
          if (resp.status === 'success') {
            this.messageService.add({
              severity: 'success',
              summary: 'Success',
              detail: resp.message,
              life: 3000,
            });
            this.dialogRef.close();
            //this.getAllLeads();
          } else {
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: resp.message,
              life: 3000,
            });
          }
          this.isDataSaving = false;
        },
        error: (error) => {
          // Handle error
          this.isDataSaving = false;
          console.error('error:', error);
        },
      });
    }
  }

  public modalClose() {
    this.dialogRef.close();
  }
}
