<div class="container">

  <div class="page-head-container">
    <h4 class="page-title"><i class="fa-regular fa-dashboard"></i> Overview</h4>
    <!-- Filter Section -->

    <!-- <div class="d-flex align-items-center">
      <div class="page-head-container-label">Year:</div>
      <p-calendar [(ngModel)]="selectedDate" view="year" dateFormat="yy" [readonlyInput]="true" [showIcon]="true"
        [style.width]="'110px'" />
      <button id="apply-filter" class="btn btn-sm btn-outline-dark action-button-outline ml-1"
        (click)="applyFilter()"><i class="fa-solid fa-check"></i> {{ "Apply" | translate }}</button>
    </div> -->
  </div>
  <ng-container *ngIf="sharedService.isProjectChangeInProc">
    <app-project-loading-indicator />
  </ng-container>

  <ng-container *ngIf="!sharedService.isProjectChangeInProc">
    <div class="row" style="height: 310px">
      <div class="col-md-9">
        <div class="d-flex justify-content-between align-items-center mb-3">
          <h5 class="mb-0">Project Performance (Last {{leadsFilter.days}} Days)</h5>
          <p class="m-0 d-flex align-items-center">
            <i class="fa-regular fa-calendar"></i>
            <select [(ngModel)]="leadsFilter.days" (change)="onDaysChange()" class="form-control custom-select"
              style="width: 150px; line-height: 1;margin-left: 6px;">
              <option [value]="7">Last 7 Days</option>
              <option [value]="30">Last 30 Days</option>
              <option [value]="90">Last 90 Days</option>
            </select>
          </p>
        </div>
        <div class="statistics-details d-flex align-items-center justify-content-between ml-3 me-3">
          <div *ngIf="leadsMetricsTotalLeads">
            <p class="statistics-title">Total Leads</p>
            <h3 class="rate-percentage">{{leadsMetricsTotalLeads.currentPeriodTotal}} <span
                class="{{leadsMetricsTotalLeads.comparisonIndicator === '+' ? 'text-success' : leadsMetricsTotalLeads.comparisonIndicator === '-' ? 'text-danger' : ''}} text-medium">{{leadsMetricsTotalLeads.comparisonIndicator.replace('-','')}}{{leadsMetricsTotalLeads.comparison}}</span>
            </h3>
            <p class="statistics-title">Compared to {{leadsMetricsTotalLeads.previousPeriodTotal}}</p>
          </div>
          <ng-container *ngFor="let m of leadsMetrics">
            <div>
              <p class="statistics-title">{{m.statusCategory === 'New Lead' ? 'Pending':
                m.statusCategory}}
              </p>
              <h3 class="rate-percentage">{{m.currentPeriodTotal}} <span
                  class="{{m.comparisonIndicator === '+' ?
                (m.statusId === 2 || m.statusId === 3 || m.statusId === 5 ? 'text-danger' : 'text-success')
                : m.comparisonIndicator === '-' ? (m.statusId === 2 || m.statusId === 3 || m.statusId === 5 ? 'text-success' : 'text-danger') : ''}} text-medium">{{m.comparisonIndicator.replace('-','')}}{{m.comparison}}</span>
              </h3>
              <p class="statistics-title">Compared to {{m.previousPeriodTotal}}</p>
            </div>
          </ng-container>
        </div>

        <div class="card card-rounded mt-4">
          <div class=card-body>
            <div class="d-sm-flex justify-content-between align-items-start">
              <div>
                <h5 class="card-title card-title-dash">Lead Conversion</h5>
              </div>
            </div>
            <div class="me-3 mt-4">
              <div id=sales-trend-legend></div>
            </div>
            <div class="chartjs-bar-wrapper mt-3">
              <p-chart *ngIf="leadsConversions && leadsConversions.length > 0" type="line" [data]="data_sales_trend"
                [options]="options_sales_trend" height="250px"></p-chart>
            </div>
          </div>
        </div>

        <div class="card card-rounded mt-4 mb-5">
          <div class="card-body">
            <div class="d-flex justify-content-between" style="margin-bottom: 4px;">
              <h5 class="card-title card-title-dash card-heading">
                <i class="fa-regular fa-person-walking-arrow-loop-left mr-1"></i>Follow-ups
              </h5>
              <a *ngIf="events && events.length > 3" class="card-link">{{events.length - 3}} More</a>
            </div>
            <ul *ngIf="events" class="list-group list-group-flush today-schedule">
              <li *ngFor="let e of events | slice:0:3; let i=index" class="list-group-item">
                <span [title]="e.eventType">
                  <i [ngStyle]="{'color' : e.backColor}" class="fa-solid {{
                  e.eventTypeId === 1 ? 'fa-handshake'
                  : e.eventTypeId === 2 ? 'fa-laptop'
                  : e.eventTypeId === 3 ? 'fa-person-walking'
                  : e.eventTypeId === 4 ? 'fa-screencast'
                  : e.eventTypeId === 5 ? 'fa-person-walking-arrow-loop-left'
                  : 'fa-circle-dot'}} mr-1"></i>
                  {{e.title}}
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div class="col-md-3">
        <div class="card card-rounded mb-3">
          <div class="card-body">
            <div class="d-flex justify-content-between" style="margin-bottom: 4px;">
              <h5 class="card-title card-title-dash card-heading">
                <i class="fa-regular fa-calendar-check mr-1"></i>Today's Schedules
              </h5>
              <a *ngIf="events && events.length > 3" class="card-link" [routerLink]="'events'">{{events.length -
                3}} More</a>
            </div>
            <ul class="list-group list-group-flush today-schedule">
              <ng-container *ngIf="events && events.length > 0; else noEvent">
                <li *ngFor="let e of events | slice:0:3; let i=index" class="list-group-item"
                  style="padding: 10px 7px;">
                  <div [title]="e.eventType">
                    <i [ngStyle]="{'color' : e.backColor}" class="fa-solid {{
                    e.eventTypeId === 1 ? 'fa-handshake'
                    : e.eventTypeId === 2 ? 'fa-laptop'
                    : e.eventTypeId === 3 ? 'fa-person-walking'
                    : e.eventTypeId === 4 ? 'fa-screencast'
                    : e.eventTypeId === 5 ? 'fa-person-walking-arrow-loop-left'
                    : 'fa-circle-dot'}} mr-1"></i>
                    <span>{{e.title}}</span>
                    <p class="event-time">{{(e.startDateTime | date:'h:mm a') + ' - ' + (e.endDateTime |
                      date:'h:mm a')}} <span class="badge ms-1"
                        [ngStyle]="{'background-color' : (!e.statusBgColor ? '#f9f9f9' : e.statusBgColor), 'color': (!e.statusColor ? '#333' : e.statusColor)}">
                        {{e.status}}</span>
                    </p>
                    <p class="event-time" style="color: cadetblue">Organizer:
                      {{e.organizer?.split(';')[1]}}
                    </p>
                  </div>
                </li>
              </ng-container>

              <ng-template #noEvent>
                <li class="list-group-item text-center m-4">
                  <i class="far fa-calendar-times fa-2x text-muted"></i>
                  <p class="mt-2">No schedules for today.</p>
                </li>
              </ng-template>

            </ul>
          </div>
        </div>

        <div class="card card-rounded mb-5">
          <div class=card-body>
            <div class=row>
              <div class=col-lg-12>
                <div class="d-flex justify-content-between align-items-center mb-3">
                  <div>
                    <h5 class="card-title card-title-dash card-heading">
                      <i class="fa-regular fa-chart-user mr-1"></i>
                      Top Performer
                    </h5>
                  </div>
                </div>
                <div class=mt-3>

                  <ng-container *ngIf="topPerformers && topPerformers.length > 0; else noPerformer"></ng-container>
                  <div *ngFor="let performer of topPerformers"
                    class="wrapper d-flex align-items-center justify-content-between py-2 border-bottom">
                    <div class=d-flex [pTooltip]="tooltipContent">
                      <img [src]="performer.profilePictureUrl" class="img-sm rounded top-performer-img" alt="profile" />
                      <div class="wrapper ms-3">
                        <p class="ms-1 mb-1 fw-bold">{{ performer.userName }}</p>
                        <small class="text-muted mb-0 position-relative">Achievement Rate: <b
                            [ngClass]="{'text-danger': performer.successRatePercentage < 10, 'text-success': performer.successRatePercentage > 10}">{{
                            performer.successRatePercentage}}%</b>
                        </small>
                      </div>
                    </div>
                    <img *ngIf="performer.successRatePercentage > 25" title="Rewarded for achievement above 25%"
                      src="../assets/img/trophy.png" height="24px" />
                    <ng-template #tooltipContent let-data="performer">
                      <div class="flex align-items-center">
                        <p class="performer-tooltip">
                          Total Leads: {{ performer.totalLeadsCount }}<br>
                          Qualified Leads: {{ performer.qualifiedLeadsCount }}<br>
                          Demonstrated Leads: {{ performer.demonstratedLeadsCount }}<br>
                          Completed Deals: {{ performer.completedDealsCount }}<br>
                          Pending Deals: {{ performer.pendingDealsCount }}
                        </p>
                      </div>
                    </ng-template>
                  </div>

                  <ng-template #noPerformer>
                    <li class="list-group-item text-center m-4">
                      <i class="far fa-user fa-2x text-muted"></i>
                      <p class="mt-2">Not calculated yet.</p>
                    </li>
                  </ng-template>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</div>
