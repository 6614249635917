import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Remarks } from 'src/app/models/Remarks';
import { DealStatus } from 'src/app/models/Status';
import { Result } from 'src/app/models/Result';
import { ApiServiceService } from 'src/app/services/api-service.service';
import { AuthServiceService } from 'src/app/services/auth-service.service';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Leads, LeadsFilter } from 'src/app/models/Leads';
import { Deals } from 'src/app/models/Deals';
@Component({
  selector: 'app-dealview',
  templateUrl: './dealview.component.html',
  styleUrls: ['./dealview.component.css'],
})
export class DealviewComponent {
  public statusTypeId: number = 3; // Incase of deal component
  @Input() deal: any | Deals;
  @Input() projectId: number | undefined;
  @Output() modalClosed: EventEmitter<any> = new EventEmitter<any>();
  public logs: Remarks[] = [];
  public statusList: DealStatus[] = [];
  public remarks: Remarks = new Remarks();
  public isLoading: boolean = false;
  public lead: Leads = new Leads();
  constructor(
    public apiService: ApiServiceService,
    public authService: AuthServiceService,
    public dialogConfig: DynamicDialogConfig,
    private dialogRef: DynamicDialogRef,
    public activeModal: NgbActiveModal,
    public modalService: NgbModal,
    private messageService: MessageService,
    private confirmationService: ConfirmationService
  ) {
    this.deal = this.dialogConfig.data.data;
    this.projectId = this.dialogConfig.data.projectId;
    this.remarks.statusId = 3;
    this.deal.assigneesArray = this.deal.assignees.split(',');
  }
  ngOnInit(): void {
    this.getRemarksByDealId(this.deal?.id);
    this.getDealStatus();
    this.getLeadById(this.deal?.leadId);
  }
  public getLeadById(id): void {
    const leadsFilter = new LeadsFilter();
    leadsFilter.id = id;
    leadsFilter.projectId = this.projectId;

    this.apiService.getLeadById(leadsFilter).subscribe({
      next: (resp: Result) => {
        if (resp.status === 'success') {
          this.lead = resp.data;
          console.log(this.lead);
        }
      },
      error: (error) => {
        // Handle error
        console.error('error:', error);
      },
    });
  }
  public getDealStatus(): void {
    this.apiService.getDealStatus(this.projectId, this.statusTypeId).subscribe({
      next: (resp: Result) => {
        if (resp.status === 'success') {
          this.statusList = resp.data;
          this.remarks.statusId = this.statusList.map((x) => x.id)[0];
        } else {
          this.statusList = resp.data;
          this.messageService.add({ severity: 'warn', summary: 'Warning', detail: resp.message, life: 3000, });
        }
      },
      error: (error) => {
        console.error('error:', error);
      },
      complete: () => {
        ;
      },
    });
  }
  public validateAndSetData() {
    if (!this.remarks.details || this.remarks.details === '') {
      this.messageService.add({
        severity: 'warn',
        summary: 'Warning',
        detail: 'Please Add Some Remarks',
        life: 3000,
      });
      return false;
    }
    if (!this.remarks.statusId || this.remarks.statusId === 0) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Warning',
        detail: 'Please Select Status for Remarks',
        life: 3000,
      });
      return false;
    }

    else {
      return true;
    }
  }
  public addRemarks(): void {
    if (this.validateAndSetData()) {
      this.remarks.dateCreated = new Date();
      this.remarks.userId = this.authService.currentUserValue.id;
      this.remarks.isActive = true;
      this.remarks.dealId = this.deal?.id;
      this.apiService.addUpdateDealLogs(this.remarks).subscribe({
        next: (resp: Result) => {
          if (resp.status === 'success') {
            this.getRemarksByDealId(this.deal?.id);
            this.messageService.add({
              severity: 'success',
              summary: resp.status,
              detail: resp.message,
              life: 3000,
            });
            this.remarks.details = '';
          } else {
            this.messageService.add({ severity: 'warn', summary: 'Warning', detail: resp.message, life: 3000, });
          }
        },
        error: (error) => {
          // Handle error
          console.error('error:', error);
        },
        complete: () => { },
      });
    }
  }
  public getRemarksByDealId(id: number): void {
    this.isLoading = true;
    this.apiService.getRemarksByDealId(id).subscribe({
      next: (resp: Result) => {
        this.isLoading = false;
        if (resp.status === 'success') {
          this.logs = resp.data;
          console.log(this.logs);
          console.log(resp.data);
        } else {
          this.logs = resp.data;
        }
      },
      error: (error) => {
        // Handle error
        console.error('error:', error);
      },
    });
  }

  public deleteLogById(id: number): void {
    this.confirmationService.confirm({
      header: 'Confirmation',
      message: 'Do you want to delete this lead?',
      accept: () => {
        this.apiService.deleteLogById(id).subscribe({
          next: (resp: Result) => {
            if (resp.status === 'success') {
              this.getRemarksByDealId(this.deal?.id);
              this.messageService.add({
                severity: 'success',
                summary: resp.status,
                detail: resp.message,
                life: 3000,
              });
            } else {
              this.getRemarksByDealId(this.deal?.id);
              this.messageService.add({ severity: 'warn', summary: 'Warning', detail: resp.message, life: 3000, });
            }
          },
          error: (error) => {
            // Handle error
            console.error('error:', error);
          },
        });
      },
      reject: () => {

      },
    });
  }
  public editLogById(item: any) {
    this.remarks = item;
    this.remarks.isEdit = true;
  }
  public resetLogForm(): void {
    this.remarks = new Remarks();
    this.remarks.statusId = 8;
  }
  onClose(): void {
    this.dialogRef.close(true);
  }
  onCancel(): void {
    this.dialogRef.close(true);
  }
}
