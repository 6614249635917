<div class="container">
  <div class="page-head-container" style="margin: -5px -5px 0.5rem -5px !important;">
    <h4 class="page-title"><i class="fa-regular fa-memo-circle-info"></i> {{ "CONTACTS" | translate }}
    </h4>
    <!-- Filter Section -->
    <div>
      <p-multiSelect  *ngIf="isConvertable" name="usersselect" [options]="usersList" [(ngModel)]="selectedUsers" placeholder="Select Assignees"
        optionLabel="name" display="chip">
      </p-multiSelect>
      <button *ngIf="isConvertable" class="btn btn-sm btn-outline-dark action-button-outline ml-3" (click)="null">
        <i class="fa-light fa-filter-list"></i>
        Convert To Lead</button>
      <button *ngIf="isAuthorized" class="btn btn-sm btn-outline-dark action-button-outline ml-3"
        (click)="filterVisible = true"><i class="fa-light fa-filter-list"></i>
        Apply Filters</button>
    </div>
  </div>

  <ng-container *ngIf="isAuthorized; else unauthorized">
    <div class="table-responsive" style="overflow-y: auto; height: calc(100vh - 100px); zoom: 80%;"
      *ngIf="!isLoading; else loader">
      <table class="table table-hover parentTable" *ngIf="contacts != null && contacts.length > 0; else noData">
        <thead>
          <tr class="sticky-header">
            <th>
              <p-checkbox class="filter-checkbox" [binary]="true" (click)="headerCheckBoxClicked()"></p-checkbox>
            </th>
            <th align="center">
              #
            </th>
            <th style="width: 230px;">Contact Name</th>
            <th>Cell Number</th>
            <th>Phone Number</th>
            <th>Address Line 1</th>
            <th>Address Line 2</th>
            <th>City</th>
            <th>State</th>
            <th>Zip</th>
            <th>County</th>
            <th>Home VAL</th>
            <th>Exact Age</th>
            <th>Est Age</th>
            <th>Gender</th>
            <th>Marital Status</th>
            <th>Ethnic Code</th>
            <th>Est Income</th>
            <th>Est Wealth</th>
            <th>Med HMEVL</th>
            <th>Latitude</th>
            <th>Longitude</th>
            <th>First in Household</th>
            <th>Child Present</th>
            <th>MFDU</th>
            <th>Length of Residence</th>
            <th>Address Type</th>
            <th>Dwelling Type</th>
            <th>Homeowner Type</th>
            <th>Property</th>
            <th>Median Years in School</th>
            <th>DPV Code</th>
            <th>Time Zone</th>
            <th>Birthdate</th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngFor="let contact of contacts; let i = index">
            <tr>
              <td>
                <ng-container *ngIf="!contact.isConvertedToLead">
                  <p-checkbox name="convertedToLead{{i}}" class="filter-checkbox" (click)="checkBoxClicked(i)"
                    [(ngModel)]="contact.convertToLead" [binary]="true"></p-checkbox>
                  <!-- <input name="convertedToLead{{i}}" class="" type="checkbox"  > -->
                </ng-container>

              </td>
              <td align="center">
                {{ contact.id }}
              </td>
              <td>
                <div style="width: 230px;">
                  <strong>{{ contact.title }} {{ contact.firstName }} {{ contact.initial }} {{ contact.lastName
                    }}</strong>
                </div>
              </td>
              <td align="center">
                <p class="phone">{{ !contact.cellPhone ? '-' : contact.cellPhone }}</p>
              </td>
              <td align="center">
                <p class="phone">{{ contact.phoneNumber }}</p>
              </td>
              <td>{{ contact.addressLine1 }}</td>
              <td>{{ contact.addressLine2 }}</td>
              <td>{{ contact.city }}</td>
              <td>{{ contact.state }}</td>
              <td>{{ contact.zip }}</td>
              <td>{{ contact.countyName + ' (' + contact.countyNumber + ')' }}</td>
              <td><span class="font-weight-600">{{ contact.homeValue | currency }}</span></td>
              <td>{{ contact.exactAge }}</td>
              <td>{{ contact.estAge }}</td>
              <td>{{ contact.gender }}</td>
              <td>{{ contact.marital }}</td>
              <td>{{ contact.ethnicCD }}</td>
              <td>{{ contact.estIncome }}</td>
              <td>{{ contact.estWealth }}</td>
              <td>{{ contact.medHMEVL }}</td>
              <td>{{ contact.latitude }}</td>
              <td>{{ contact.longitude }}</td>
              <td>{{ contact.firstInHousehold }}</td>
              <td>{{ contact.childPresent }}</td>
              <td>{{ contact.mfdu }}</td>
              <td>{{ contact.lengthOfResidence }}</td>
              <td>{{ contact.addrType }}</td>
              <td>{{ contact.dwellingType }}</td>
              <td>{{ contact.homeownerType }}</td>
              <td>{{ contact.property }}</td>
              <td>{{ contact.medianYrsInSchool }}</td>
              <td>{{ contact.dpvcode }}</td>
              <td>{{ contact.timeZone }}</td>
              <td>{{ contact.birthdate }}</td>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </div>
    <!-- Pagination DIV -->
    <div class="pagination-container">
      <div class="d-flex align-items-center">
        <span class="mr-3">Page {{ currentPage }} of {{ calculateTotalPages(totalCount, pageSize) }} of
          {{totalCount}}</span>
        <select id="pageSize" class="form-select" [(ngModel)]="pageSize" (change)="onPageSizeChange()" style="
  width: 68px;
  text-align: center;
  font-size: 12px;">
          <option class="dropdown-item d-flex align-items-center" value="10">10</option>
          <option class="dropdown-item d-flex align-items-center" value="20">20</option>
          <option class="dropdown-item d-flex align-items-center" value="50">50</option>
          <option class="dropdown-item d-flex align-items-center" value="100">100</option>
          <option class="dropdown-item d-flex align-items-center" value="500">500</option>
          <option class="dropdown-item d-flex align-items-center" value="1000">1000</option>
        </select>
      </div>



      <div style="max-width: 500px;">
        <button class="btn btn-sm btn-link text-dark mr-2" (click)="onPageChange(1)" [disabled]="currentPage === 1">
          <i class="fa-sharp fa-solid fa-angles-left"></i></button>
        <button class="btn btn-sm btn-link text-dark mr-2" (click)="onPageChange(currentPage - 1)"
          [disabled]="currentPage === 1"><i class="fa-sharp fa-solid fa-angle-left"></i></button>
        <ng-container *ngFor="let page of displayedPages">
          <button class="btn btn-sm" (click)="onPageChange(page)" [class.btn-danger]="page === currentPage"
            [class.active]="page === currentPage">
            {{ page }}
          </button>
        </ng-container>
        <button class="btn btn-sm btn-link text-dark" (click)="onPageChange(currentPage + 1)"
          [disabled]="currentPage === calculateTotalPages(totalCount, pageSize)"><i
            class="fa-sharp fa-solid fa-angle-right"></i></button>
        <button class="btn btn-sm btn-link text-dark" (click)="onPageChange(totalPages)"
          [disabled]="currentPage === calculateTotalPages(totalCount, pageSize)"><i
            class="fa-sharp fa-solid fa-angles-right"></i></button>
      </div>
    </div>
  </ng-container>

  <ng-template #noData>
    <tr>
      <td colspan="34" class="text-center">No contacts available.</td>
    </tr>
  </ng-template>

  <ng-template #unauthorized>
    <div class="password-prompt">
      <h3>Enter Password</h3>
      <p>To access this page, you need a special password provided by your administrator</p>
      <input type="password" class="form-control" [(ngModel)]="password" placeholder="Password" style="width: 150px;" />
      <button (click)="submitPassword()" class="btn btn-sm btn-outline-dark action-button-outline mt-3">Submit</button>
      <p *ngIf="errorMessage" class="error">{{ errorMessage }}</p>
    </div>
  </ng-template>


  <ng-template #loader>
    <app-project-loading-indicator height="calc(100vh - 165px)" />
  </ng-template>

  <ng-template #noData>
    <app-nodatafound message="No data available yet." height="calc(100vh - 165px)"></app-nodatafound>
  </ng-template>


  <p-sidebar [(visible)]="filterVisible" position="right" styleClass="w-25rem">
    <ng-template pTemplate="header">
      <h5 class="font-semibold text-xl">Filter Cost Details</h5>
    </ng-template>
    <div (keydown.escape)="filterVisible = false">
      <div>

        <div class="form-group">
          <label for="name" class="form-label">Name</label>
          <input type="text" id="name" [(ngModel)]="filters.name" class="form-control" placeholder="Enter keyword">
        </div>
        <div class="form-group">
          <label for="cellPhone" class="form-label">Cell Number</label>
          <input type="text" id="cellPhone" [(ngModel)]="filters.cellPhone" class="form-control"
            placeholder="Enter Cell Number">
        </div>

        <div class="form-group">
          <label for="phoneNumber" class="form-label">Phone Number</label>
          <input type="text" id="phoneNumber" [(ngModel)]="filters.phoneNumber" class="form-control"
            placeholder="Enter Phone Number">
        </div>
        <div class="form-group">
          <label for="addressLine1" class="form-label">Address Line 1</label>
          <input type="text" id="addressLine1" [(ngModel)]="filters.addressLine1" class="form-control"
            placeholder="Enter Address Line 1">
        </div>

        <div class="form-group">
          <label for="cityId" class="form-label">City ID</label>
          <input type="number" id="cityId" [(ngModel)]="filters.cityId" class="form-control"
            placeholder="Enter City ID">
        </div>

        <div class="form-group">
          <label for="state" class="form-label">State</label>
          <input type="text" id="state" [(ngModel)]="filters.state" class="form-control" placeholder="Enter State">
        </div>

        <div class="form-group">
          <label for="zip" class="form-label">Zip</label>
          <input type="text" id="zip" [(ngModel)]="filters.zip" class="form-control" placeholder="Enter Zip">
        </div>

        <div class="form-group">
          <label for="countyId" class="form-label">County ID</label>
          <input type="number" id="countyId" [(ngModel)]="filters.countyId" class="form-control"
            placeholder="Enter County ID">
        </div>

        <div class="form-group">
          <label for="homeownerTypeId" class="form-label">Homeowner Type ID</label>
          <input type="number" id="homeownerTypeId" [(ngModel)]="filters.homeownerTypeId" class="form-control"
            placeholder="Enter Homeowner Type ID">
        </div>

        <div class="form-group">
          <label for="propertyTypeId" class="form-label">Property Type ID</label>
          <input type="number" id="propertyTypeId" [(ngModel)]="filters.propertyTypeId" class="form-control"
            placeholder="Enter Property Type ID">
        </div>

        <div class="form-group">
          <label for="ethnicCDId" class="form-label">Ethnic Code ID</label>
          <input type="number" id="ethnicCDId" [(ngModel)]="filters.ethnicCDId" class="form-control"
            placeholder="Enter Ethnic Code ID">
        </div>

        <div class="form-group">
          <label for="genderId" class="form-label">Gender ID</label>
          <input type="number" id="genderId" [(ngModel)]="filters.genderId" class="form-control"
            placeholder="Enter Gender ID">
        </div>



        <div class="modal-footer">
          <button type="button" class="btn btn-outline-secondary action-button-outline" (click)="resetFilters()">
            <i class="fas fa-times me-1"></i>Clear All
          </button>
          <button type="button" class="btn btn-outline-secondary action-button-outline" (click)="filterVisible = false">
            Close
          </button>
          <button class="btn btn-primary action-button" (click)="applyFilters()">
            <i class="fas fa-search me-1"></i>Search
          </button>
        </div>
      </div>
    </div>
  </p-sidebar>
</div>
