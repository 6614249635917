export class Contact {
  id: number;
  firstName: string;
  initial: string;
  lastName: string;
  name: string;
  addressLine1: string;
  addressLine2: string;
  city: string;
  state: string;
  zip: string;
  zip4: string;
  deliveryPoint: string;
  carrierRoute: string;
  countyNumber: string;
  countyName: string;
  latitude: string;
  longitude: string;
  firstInHousehold: string;
  childPresent: string;
  mfdu: string;
  exactAge: string;
  estAge: string;
  estIncome: string;
  lengthOfResidence: string;
  addrType: string;
  dwellingType: string;
  homeownerType: string;
  property: string;
  medHMEVL: string;
  marital: string;
  ethnicCD: string;
  title: string;
  medianYrsInSchool: string;
  gender: string;
  dpvcode: string;
  estWealth: string;
  phoneNumber: string;
  cellPhone: string;
  timeZone: string;
  birthdate: string;
  pageNumber: number;
  pageSize: number;
  cityId: number;
  countyId: number;
  exactAgeId: number;
  estAgeId: number;
  estIncomeId: number;
  lengthOfResidenceId: number;
  addrTypeId: number;
  homeownerTypeId: number;
  propertyTypeId: number;
  medHMEVLId: number;
  ethnicCDId: number;
  genderId: number;
  estWealthId: number;
  isExported: boolean;
  isConvertedToLead: boolean=false;
  convertToLead: boolean=false;
  homeValue: string;
}
